import React, { Component } from "react";
//import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const description = this.props.data.description;
    
    const networks = this.props.data.social.map(function (network) {
      return (
          <a key={network.name} href={network.url}>
              <i className={network.className}></i>
          </a>
      );
    });

    // <ParticlesBg type="custom" bg={true} color="#8BC7F4" />
    /*
            <li>
              <a className="smoothscroll" href="#resume">
                Serviços
              </a>
            </li>
    */
    return (
      <header id="home" className="h_header">

        <nav id="nav-wrap">
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a className="smoothscroll" href="#home">
                Home
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#about">
                Sobre mim
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#portfolio">
                Trabalhos
              </a>
            </li>

            <li>
              <a className="smoothscroll" href="#contact">
                Contato
              </a>
            </li>
            
            <li>
              <a className="smoothscroll" href="#testimonials">
                O que dizem
              </a>
            </li>

          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              <h1 className="responsive-headline">{name}</h1>
            </Fade>
            <Fade bottom duration={1200}>
              <h3>{description}</h3>
            </Fade>
            <hr />
            <Fade bottom duration={1200}>
              <h3>
                <div className="header-social-links">{networks}</div>
              </h3>
            </Fade>
          </div>
        </div>

        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
