import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";
// import $ from 'jquery';

class Contact extends Component {

  constructor(props) {
    super(props);
    this.state = { contactName: '', contactEmail: '', contactFone: '', contactMessage: '', loading: false, msg: {}};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) { 
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState({
      [name]: value
    });
  }

  showLoading() {
    this.setState({
      loading: true,
      msg: {}
    });
  }

  showSuccess() {
    this.setState({
      loading: false,
      msg: {success: true, warning: false, error: false}
    });
    this.clearMessages()
  }

  showError() {
    this.setState({
      loading: false,
      msg: {success: false, warning: false, error: true}
    });
    this.clearMessages()
  }

  showWarning() {
    this.setState({
      loading: false,
      msg: {success: false, warning: true, error: false}
    });
    this.clearMessages()
  }

  clearMessages() {
    setTimeout(() => {
      this.setState({
        loading: false,
        msg: {}
      });
    }, 5000);
  } 

  handleSubmit = (event) => {
    event.preventDefault();
    if(this.state.contactName === '' 
      || this.state.contactEmail === '' 
      || this.state.contactFone === '' 
      || this.state.contactMessage === '') {
      this.showWarning()
      return
    }
    this.showLoading()
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        'name': this.state.contactName,
        'mail': this.state.contactEmail,
        'fone': this.state.contactFone,
        'message': this.state.contactMessage
      })};
    fetch('/api/sendmail', requestOptions)
    .then(response => {
        if (!response || response.status !== 202 ) { // Accepted
          this.showError()
        } else {
          this.showSuccess()
        }
      })
      .catch((error) => {
        console.log(error)
        this.showError()
      });
  }

  render() {
    if (!this.props.data) return null;
    
    const { loading, msg } = this.state;
    const message = this.props.data.contactmessage;

    return (
      <section id="contact">
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="three columns header-col">
              <h1>
                <span>Email</span>
              </h1>
            </div>

            <div className="nine columns">
              <p className="lead">{message}</p>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="twelve columns">
              <form onSubmit={this.handleSubmit} id="contactForm" name="contactForm">
                <fieldset>
                  <div>
                    <label htmlFor="contactName">
                      Nome <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactName"
                      name="contactName"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactEmail">
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="mail"
                      defaultValue=""
                      size="35"
                      id="contactEmail"
                      name="contactEmail"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactFone">
                      Telefone / Celular <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactFone"
                      name="contactFone"
                      onChange={this.handleChange}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactMessage">
                      Mensagem <span className="required">*</span>
                    </label>
                    <textarea
                      cols="50"
                      rows="15"
                      id="contactMessage"
                      name="contactMessage"
                      onChange={this.handleChange}
                    ></textarea>
                  </div>

                  <div>
                    <button type='submit' className="submit">Enviar</button>
                    
                    { loading && <LoadingIco />}
                    { msg && <Message msg={this.state.msg} />}
                  </div>
              
                </fieldset>
              </form>
            </div>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;


class Message extends Component {
  render() {
    // msg {success: false, warning=false, error=false}
    if (this.props.msg.success) {
      return <span id="message-success">
              <i className="fa fa-check"></i>Sua mensagem foi enviada com sucesso, Obrigado!
            </span>
    }
    else if (this.props.msg.warning) {
      return <span id="message-warning"> Favor preencher todos os campos.</span>
    }
    else if (this.props.msg.error) {
      return <span id="message-error"> Ops! Ocorreu um erro, tente mais tarde!</span>
    } 
    return null
  }
}

class LoadingIco extends Component {
  render() {
    return <span id="image-loader">
      <img alt="" src="images/loader.gif" />
    </span>
  }
}