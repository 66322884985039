import React, { Component } from "react";
import { Slide } from "react-reveal";

class Testimonials extends Component {
  render() {
    if (!this.props.data) return null;

    const title = this.props.data.title;
    const testimonials = this.props.data.messages.map(function(testimonials) {
      return (
        <li key={testimonials.user}>
          <blockquote>
            <p>{testimonials.text}</p>
            <cite>{testimonials.user}</cite>
          </blockquote>
        </li>
      );
    });

    return (
      <section id="testimonials">
        <Slide left duration={1300}>
          <div className="text-container">
            <div className="row">
              <div className="two columns header-col">
                <h1>
                  <span>{title}</span>
                </h1>
              </div>

              <div className="ten columns flex-container">
                <ul className="slides">{testimonials}</ul>
              </div>
            </div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default Testimonials;
